import React from "react";

import { MERCHANTS_FEEDBACKS } from "./feedback.constants";
import SwiperElement from "../../common/swiper-element";

const Slider = () => {
  return (
    <div className="flex items-center justify-center flex-col h-[368px] lg:h-[316px] w-full">
      <SwiperElement
        items={MERCHANTS_FEEDBACKS}
        shouldAutoPlay={true}
        wrapperClassName="md:max-w-[828px] w-full h-full margin-0"
        slidesPerView={1.07}
        renderCard={({ logo: Logo, name, feedback, position, }, index) => (
          <div className={`flex flex-col items-center text-center border ${index === 0 && 'ml-[25px]'} md:ml-0 rounded-xl border border-solid border-light-grey-outline shadow-card h-[calc(100%-36px)] overflow-hidden justify-between pt-6 pb-4`}>
            <Logo width="132" height="39"  />
            <div className="flex flex-col justify-center gap-11 md:gap-[35px] items-center max-w-[828px]" >
              <label className="md:text-4xl text-2xl text-black font-Poppins-BoldItalic w-full max-w-[300px] md:max-w-[440px] lg:max-w-[720px] whitespace-pre-wrap">
                {feedback}
              </label>
            </div>
            <div>
              <div className="text-sm md:text-base text-blue font-Poppins-Bold">
                {name}
              </div>
              <label className="text-sm md:text-base text-black-text font-Poppins-Regular w-full max-w-[646px] whitespace-pre-wrap">
                {position}
              </label>
            </div>
          </div>
        )} />
    </div>
  );
};

export default Slider;