import {
  AramexLogo, BoltLogo, BostaLogo, DHlLogo,
  JTLogo, MovexLogo, NetLogo, OtexLogo, R2SLogo, RunnersLogo, SmsaLogo,
  SpeedafLogo, SprintLogo, TransporterLogo, YFSLogo
} from "../../../assets/icons";

export const COURIERS_LOGOS = [
  { label: 'Aramex', logo: AramexLogo },
  { label:'Bosta', logo: BostaLogo },
  { label: 'JT', logo: JTLogo },
  { label: 'MovexLogo', logo: MovexLogo },
  { label: 'Smsa', logo: SmsaLogo },
  { label: 'Speedaf', logo: SpeedafLogo },
  { label: 'NetLogo', logo: NetLogo },
  { label: 'OtexLogo', logo: OtexLogo },
  { label:'Dhl', logo: DHlLogo },
  { label: 'Bolt', logo: BoltLogo },
  { label: 'Transporter', logo: TransporterLogo },
  { label: 'Runners', logo: RunnersLogo },
  { label: 'R2SLogo', logo: R2SLogo }, //
  { label: 'Sprint', logo: SprintLogo },
  { label: 'YFS', logo: YFSLogo }
]