import React from "react"

const CardIcon = () => (
  <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.8462 1H3.15385C1.96431 1 1 1.94256 1 3.10526V18.8947C1 20.0574 1.96431 21 3.15385 21H26.8462C28.0357 21 29 20.0574 29 18.8947V3.10526C29 1.94256 28.0357 1 26.8462 1Z" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M26.8462 1H3.15385C1.96431 1 1 1.94256 1 3.10526V18.8947C1 20.0574 1.96431 21 3.15385 21H26.8462C28.0357 21 29 20.0574 29 18.8947V3.10526C29 1.94256 28.0357 1 26.8462 1Z" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19 15.0001L23 15.0001" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1 7L29 7" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default CardIcon