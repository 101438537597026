import React from "react"
import GetStartedButton from "../../common/get-started-button"
import { InstagramIcon } from "../../../assets/icons"
import useViewport from "../../../hooks/use-viewport"
import defaultLocale from "../../../defaultLocal"

const StartShipping = () => {
  const { isSmallScreen } = useViewport()

  return (
    <div className="w-full justify-between">
      <div className="flex flex-col gap-5 md:gap-7 justify-start items-center mb-16 md:mb-20">
        <label className="max-w-[340px] md:max-w-[503px] font-Poppins-ExtraBold text-3xl text-center md:text-6xl text-blue">{defaultLocale.startShipping}</label>
        <GetStartedButton text={defaultLocale.getStartedForFreeBtn}/>
      </div>
      <footer className="bg-black-text w-full h-[52px] flex justify-between pl-5 pr-5 md:pl-[10.5%] md:pr-[10.3%] items-center">
        <div className="text-white text-xs font-Poppins-Medium">{defaultLocale.allRightsReserved}</div>
        <div className="flex gap-2.5 items-center">
          {!isSmallScreen &&<label className="text-white text-xs font-Poppins-Medium">{defaultLocale.connectWithUs}</label>}
          <a href="https://www.instagram.com/fincart.io/">
            <InstagramIcon />
          </a>
        </div>
      </footer>
    </div>
  )
}

export default StartShipping