import React, { useMemo } from "react"
import SelectDropdown from "../../select-dropdown"

const FormField = ({ props, register, errors = {}, setValue }) => {
  const { htmlFor, label, placeholder, type, prefixIcon: PrefixIcon, validation, renderPostfixElement = false, options} = props

  const hasError = Boolean(errors[htmlFor]);
  const errorMsg = errors[htmlFor]?.message;

  const mutualInputClassName = "font-Poppins-Regular text-xs pl-9 w-full h-9 border rounded-lg py-2 px-3 focus:outline-none focus:shadow-outline [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none hover:border-black-text"
  const inputClassName = useMemo(() => hasError? `${mutualInputClassName} border-red` : `${mutualInputClassName} border-border-grey`, [hasError])

  return (
    <>
      <div className={type === 'select'? 'w-[46%]': 'w-full'}>
        <label className="block text-gray-700 text-2xs font-Poppins-Bold mb-1 sm:max-w-[100px] base:max-w-[300px] max-h-[20px] overflow-hidden whitespace-nowrap text-ellipsis" htmlFor={htmlFor} >
          {label}
        </label>
        <div className="relative text-gray-600 focus-within:text-gray-400">
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            {PrefixIcon && <PrefixIcon isSmall />}
          </span>
          {type === 'select' ?
            <SelectDropdown options={options} register={register} htmlFor={htmlFor} validation={validation} setValue={setValue} hasError={hasError} /> :
            <input
              {...register(htmlFor, validation)}
              type={type} id={htmlFor}
              className={inputClassName}
              placeholder={placeholder}
            />}
          {typeof renderPostfixElement === 'function' && renderPostfixElement()}
        </div>
        {!!errorMsg && <label className="block text-gray-700 text-2xs font-Poppins-Regular text-red mb-1">{errorMsg}</label>}
      </div>
    </>

  )
}

export default FormField