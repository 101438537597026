const INDUSTRY_DROPDOWN_OPTIONS = [
  {id: 'fashion', label: 'Fashion & Apparel'},
  { id: 'jewelery', label: 'Jewelery & Accessories' },
  {id: 'beauty', label: 'Beauty & Personal Care'},
  {id: 'electronics', label: 'Electronics'},
  {id: 'books', label: 'Books & Stationery'},
  {id: 'home', label: 'Home Accessories'}
]

const ORDERS_DROPDOWN_OPTIONS = [
  {id: '50', label: 'Less than 50 orders'},
  { id: '100', label: '50 to 100 monthly orders' },
  {id: '300', label: '100 to 300 monthly orders'},
  {id: '600', label: '300 to 600 monthly orders'},
  {id: '1000', label: '600 to 1000 monthly orders'},
  {id: 'max', label: 'More than 1000 monthly orders'}
]

export { INDUSTRY_DROPDOWN_OPTIONS, ORDERS_DROPDOWN_OPTIONS }
