import React from "react"

import useViewport from "../../../hooks/use-viewport"
import SwiperElement from "../swiper-element"


const CardsGrid = ({ items = [], title = '' }) => {
  const { isSmallScreen } = useViewport()

  const renderCard = ({ icon: Icon = null, title, desc }, index) => (
    <div key={title} className={`flex  ${index === 0  && 'ml-[25px]'} md:m-0 px-5 md:min-w-fit flex-col items-center gap-3 md:max-w-[324px] pt-7 pb-11 rounded-xl border border-solid border-light-grey-outline shadow-card bg-white min-h-[calc(100%-36px)] md:min-h-[216px]`}>
      <div className="w-10 h-10 flex justify-center items-center">
        <Icon />
      </div>

      <div className="text-center">
        <div className="text-[20px] md:text-3xl  text-blue font-Poppins-Bold">
          {title}
        </div>
        {desc && (
          <div className="text-[15px] md:text-lg text-black font-Poppins-Regular mt-1 max-w-[284px]">
            {desc}
          </div>)}
      </div>
    </div>
  )

  const Cards = () => (
    <div className="flex gap-3 md:grid md:grid-cols-3 md:mr-0 w-full justify-center">
      {!isSmallScreen? (
        items.map((item) => (
          renderCard(item)
        ))
      ) :
        (<SwiperElement
          items={items}
          renderCard={renderCard}
          slidesPerView={1.07}
          wrapperClassName={"max-w-[100%] w-full h-full margin-0 justify-center flex min-h-[252px] justify-between"}
        />)
      }
    </div>
  )

  return (
    <div className="w-full items-center flex flex-col gap-5 md:gap-10 max-w-[996px]">
      <label className="text-black-text font-Poppins-Bold md:font-Poppins-ExtraBold text-3xl max-w-[340px] md:max-w-[703px] md:text-6xl text-center">{title}</label>
      <div className={`w-full overflow-hidden inline-flex flex-nowrap justify-center  md:pl-0`}>
        <Cards />
      </div>
    </div>
  )
}

export default CardsGrid