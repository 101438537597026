import React from "react";

import defaultLocal from '../../../defaultLocal'
import useViewport from "../../../hooks/use-viewport";

const FinancingServices = () => {
  const { isSmallScreen } = useViewport()
  const {
    financingServicesTitle: title,
    financingServicesDescTextOne: firstPart,
    financingServicesDescTextTwo: secondPart,
    financingServicesDescTextThree: thirdPart,
    financingServicesDescTextFour: fourthPart
  } = defaultLocal

  const wholeDescText = `${firstPart}${secondPart}${thirdPart}${fourthPart}`

  const getDescLabel = (text, fontSize = 'regular' ) => (
    <label className={`${fontSize === 'regular'? 'font-Poppins-Regular' : 'font-Poppins-Bold'}  text-3xl-36 text-black`}>{text}</label>
  )

  return (
    <div className="max-w-[996px] py-10 px-[25px] md:py-20 md:px-[105px] flex flex-col gap-5 justify-center items-center md:rounded-xl md:shadow-card md:border border-solid border-light-grey-outline bg-white">
      <label className="text-lime-green text-3xl md:text-7xl text-center font-Poppins-Bold md:font-Poppins-ExtraBold max-w-[340px] md:max-w-[787px]">{title}</label>
      {isSmallScreen?
        <label className="font-Poppins-Regular text-lg text-center max-w-[340px]">{wholeDescText}</label> : (
          <label className="text-center max-w-[745px]">
            {getDescLabel(firstPart)}
            {getDescLabel(secondPart, 'bold')}
            {getDescLabel(thirdPart)}
            {getDescLabel(fourthPart, 'bold')}
          </label>
        )}
    </div>
  )
}

export default FinancingServices