import React from "react"

const CourierIcon = () => (
  <svg width="43" height="41" viewBox="0 0 43 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27 15.0001H34.0736C35.3802 15.0001 36.6332 15.4567 37.5571 16.2693C38.481 17.082 39 18.1842 39 19.3335V28.0001H27" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7 11.3751L7 28.0001L27 28.0001L27 11.3751C27 10.7452 26.7366 10.1411 26.2678 9.69574C25.7989 9.25034 25.163 9.00012 24.5 9.00012L9.5 9.00012C8.83696 9.00012 8.20107 9.25034 7.73223 9.69574C7.26339 10.1411 7 10.7452 7 11.3751Z" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M30 33.0001C28.6739 33.0001 27.4021 32.4733 26.4645 31.5357C25.5268 30.598 25 29.3262 25 28.0001C25 26.674 25.5268 25.4023 26.4645 24.4646C27.4021 23.5269 28.6739 23.0001 30 23.0001C31.3261 23.0001 32.5979 23.5269 33.5355 24.4646C34.4732 25.4023 35 26.674 35 28.0001C35 29.3262 34.4732 30.598 33.5355 31.5357C32.5979 32.4733 31.3261 33.0001 30 33.0001Z" fill="white" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14 33.0001C12.6739 33.0001 11.4021 32.4733 10.4645 31.5357C9.52678 30.598 9 29.3262 9 28.0001C9 26.674 9.52678 25.4023 10.4645 24.4646C11.4021 23.5269 12.6739 23.0001 14 23.0001C15.3261 23.0001 16.5979 23.5269 17.5355 24.4646C18.4732 25.4023 19 26.674 19 28.0001C19 29.3262 18.4732 30.598 17.5355 31.5357C16.5979 32.4733 15.3261 33.0001 14 33.0001Z" fill="white" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M0.999999 5.11144L6.5389 10.6674L3.76945 13.4454C3.59517 13.6225 3.47149 13.8432 3.41135 14.0846C3.35121 14.326 3.35681 14.5791 3.42756 14.8176C3.49832 15.0561 3.63163 15.2711 3.81356 15.4402C3.9955 15.6093 4.21938 15.7263 4.46181 15.7789L17.2844 18.9598C17.5157 19.0169 17.7579 19.0131 17.9874 18.9489C18.2169 18.8847 18.4259 18.7622 18.5944 18.5932C18.7629 18.4242 18.8851 18.2144 18.9491 17.9842C19.0131 17.754 19.0168 17.5111 18.9599 17.2791L15.7889 4.41694C15.729 4.17823 15.607 3.95977 15.4353 3.78392C15.2636 3.60807 15.0483 3.48114 14.8117 3.41614C14.575 3.35113 14.3253 3.35039 14.0883 3.41397C13.8512 3.47755 13.6353 3.60318 13.4625 3.778L10.6931 6.556L5.15417 1L0.999999 5.11144Z" fill="#FBFBFB" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default CourierIcon