import React from "react"

const Order = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.8462 1H3.15385C1.96431 1 1 1.96431 1 3.15385V26.8462C1 28.0357 1.96431 29 3.15385 29H26.8462C28.0357 29 29 28.0357 29 26.8462V3.15385C29 1.96431 28.0357 1 26.8462 1Z" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.3076 1V11.7692C19.3076 12.0548 19.1942 12.3288 18.9922 12.5307C18.7903 12.7327 18.5163 12.8462 18.2307 12.8462H11.7692C11.4836 12.8462 11.2096 12.7327 11.0077 12.5307C10.8057 12.3288 10.6923 12.0548 10.6923 11.7692V1" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.2308 23.6155H23.6155" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default Order