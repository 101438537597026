import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";

import { Autoplay, FreeMode, Pagination } from "swiper/modules";


const SwiperElement = ({ items = [], shouldAutoPlay, renderCard = () => {}, slidesPerView = 1, wrapperClassName}) => {
  const modules = shouldAutoPlay? [Autoplay, FreeMode, Pagination] : [FreeMode, Pagination]
  const slidesPerViewData = { slidesPerView: slidesPerView }

  return (
    <Swiper
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      breakpoints={{
        340: {
          spaceBetween: 12,
          ...slidesPerViewData
        },
        700: {
          spaceBetween: 20,
          ...slidesPerViewData
        },
      }}
      pagination={{
        clickable: true,
      }}
      modules={modules}
      className={wrapperClassName}
    >
      {items.map((item, index) => (
        <SwiperSlide key={item.key}>
          {renderCard(item, index)}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default SwiperElement