import { GrifLogo, MeBeeLogo, CatmintLogo, TamweenatLogo  } from "../../../assets/icons";


export const MERCHANTS_FEEDBACKS = [
  { key: 'Grif',
    name: 'Youssef & Arwa',
    position: 'Founder & Account Manager',
    feedback: '“We\'re very satisfied with our orders\' success rate and the daily updating of the status of each order.”',
    logo: GrifLogo
  },
  { key: 'me-bee',
    name: 'Nadine',
    position: 'Co-founder',
    feedback: '“I had many problems with shipping companies. Fincart really helped grow my business. Our cancellation orders have decreased by 5%.”',
    logo: MeBeeLogo
  },
  { key: 'catmint',
    name: 'Sara Hesham',
    position: 'Founder',
    feedback: '“After using Fincart, I found better shipping solutions. Their data and analytics help me compare the benefits of each shipping option.”',
    logo: CatmintLogo
  },
  { key: 'tamweenat-elzekryat',
    name: 'Ahmed Mahmoud',
    position: 'Founder',
    feedback: '“Great Company to work with. Merging shipping companies in one system has helped me a lot. The team is very approachable and always available.”',
    logo: TamweenatLogo
  },
]