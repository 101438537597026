import React from "react"

const GlobeIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9999 26.9999C21.1796 26.9999 26.9999 21.1796 26.9999 13.9999C26.9999 6.82018 21.1796 0.999878 13.9999 0.999878C6.82018 0.999878 0.999878 6.82018 0.999878 13.9999C0.999878 21.1796 6.82018 26.9999 13.9999 26.9999Z" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M0.999878 14H26.9999" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.9999 13.9999C18.7543 18.7539 17.0033 23.3066 13.9999 26.9999C10.9966 23.3066 9.24554 18.7539 8.99994 13.9999C9.24554 9.2459 10.9966 4.69318 13.9999 0.999878C17.0033 4.69318 18.7543 9.2459 18.9999 13.9999V13.9999Z" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default GlobeIcon