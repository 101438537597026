import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoaderIcon } from "react-hot-toast";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('fincartLoginData');
    navigate('/login')
  }, [])

  return (
    <LoaderIcon />
  )
}

export default Logout