import React, { useState, useEffect, useRef, useMemo } from 'react'
import { ArrowDownIcon, ArrowUpIcon } from '../../assets/icons'

const SelectDropdown = ({options = [], register, htmlFor, validation, setValue, hasError}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isButtonHovered, setIsButtonHovered] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const ref = useRef()

  const { name } = useMemo(() => register(htmlFor, validation), [register])
  const mutualButtonClassName = "font-Poppins-Regular text-xs pt-2 pr-[10px] pb-[7px] pl-[15px] border rounded-lg flex justify-between align-middle w-full hover:border-black-text focus-within:border-black-text"

  const buttonClassName = hasError? `${mutualButtonClassName} border-red` : `${mutualButtonClassName} border-border-grey`
  const mutualOptionClassname = `pt-2 pb-2 pl-3 pr-3 text-xs hover:bg-light-grey hover:first-of-type:rounded-t hover:last-of-type:rounded-b cursor-pointer border-b-[1px] border-light-grey-outline`;

  const getOptionClassname = (optionId) => (
    optionId === selectedOption?.id? `${mutualOptionClassname} font-Poppins-Bold` : `${mutualOptionClassname} font-Poppins-Regular`
  )

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && event.target.id !== 'menu-element') {
        setIsMenuOpen(false);
      }
    }
    // Bind
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const selectOption = (option) => {
    setSelectedOption(option);
    setValue(name, option, { shouldValidate: true });
    setIsMenuOpen(false)
  }

  return (
    <>
      <div className="flex">
        <button
          type="button"
          ref={ref}
          onMouseEnter={() => setIsButtonHovered(true)}
          onMouseLeave={() => setIsButtonHovered(false)}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className={buttonClassName}
        >
          <div className="max-h-[20px] w-[100px] overflow-hidden whitespace-nowrap text-left text-ellipsis">
            {selectedOption? selectedOption.label: <label className="text-light-text cursor-pointer">Select</label>}
          </div>

          {isMenuOpen? <ArrowUpIcon /> : <ArrowDownIcon color={isButtonHovered? '#000000' : '#AAA8AA'} />}
        </button>
      </div>

      {isMenuOpen &&
      <div className="absolute bottom-10 left-[-4px] overflow-auto z-10 mt-2 w-[150px] rounded-md bg-white shadow-xl border-light-grey border focus:outline-none" role="menu">
        {options.map(({id, label}) => <div id="menu-element" className={getOptionClassname(id)} key={id} onClick={() => selectOption({ id, label })}>{label}</div>)}
      </div>
      }
    </>
  )
}

export default SelectDropdown