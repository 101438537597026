import React from "react"

const Person = ({ isSmall = false }) => (
  isSmall? (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.99989 9.94455C11.9723 9.94455 13.5713 8.38996 13.5713 6.47227C13.5713 4.55459 11.9723 3 9.99989 3C8.02745 3 6.42847 4.55459 6.42847 6.47227C6.42847 8.38996 8.02745 9.94455 9.99989 9.94455Z" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 16.9999C3.32788 15.6829 4.2039 14.4988 5.47963 13.6483C6.75536 12.7979 8.35235 12.3333 10 12.3333C11.6477 12.3333 13.2446 12.7979 14.5204 13.6483C15.7961 14.4988 16.6721 15.6829 17 16.9999" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  ) : (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 14.8891C18.9449 14.8891 22.1429 11.7799 22.1429 7.94455C22.1429 4.10918 18.9449 1 15 1C11.0551 1 7.85718 4.10918 7.85718 7.94455C7.85718 11.7799 11.0551 14.8891 15 14.8891Z" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.00006 29C1.65582 26.3659 3.40786 23.9978 5.95932 22.2968C8.51079 20.5959 11.7048 19.6667 15.0001 19.6667C18.2954 19.6667 21.4893 20.5959 24.0408 22.2968C26.5923 23.9978 28.3443 26.3659 29.0001 29" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
)

export default Person