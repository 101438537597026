import React from "react"

const CoinsStack = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 6.60034V23.4003C1 26.4803 7.22222 29.0003 15 29.0003C22.7778 29.0003 29 26.4803 29 23.4003V6.60034" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M29 15.0001C29 18.0801 22.7778 20.6001 15 20.6001C7.22222 20.6001 1 18.0801 1 15.0001" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15 12.2C22.732 12.2 29 9.6928 29 6.6C29 3.50721 22.732 1 15 1C7.26801 1 1 3.50721 1 6.6C1 9.6928 7.26801 12.2 15 12.2Z" stroke="#2A272B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default CoinsStack