const defaultLocale = {
  getStartedForFreeBtn: 'Get started for free',
  getStartedBtn: 'Get started',
  couriersTitle: 'Get the best-value courier',
  couriersSubtitle: 'All your shipping partners in one platform',
  benefitsTitle: 'Unlock exclusive benefits with Fincart',
  deliveryOptimizeTitle: 'Improve your delivery performance by our courier recommendation engine',
  supportTitle: 'We\'ve got your back!',
  financingServicesTitle: 'Scale your business faster with our Flexible Financing service',
  financingServicesDescTextOne: 'We offer a variety of financing options to cater for your business needs, based on your',
  financingServicesDescTextTwo: ' shipping performance ',
  financingServicesDescTextThree: 'with Fincart during a period of',
  financingServicesDescTextFour: ' +6 months',
  startShipping: 'Start shipping through Fincart',
  allRightsReserved: '© Fincart. All rights reserved.',
  connectWithUs: 'Connect with us',
  login: {
    welcomeBackText: 'Welcome back!',
    loginButton: 'Log In',
    emailFieldLabel: 'Email',
    emailFieldPlaceholder: 'username@gmail.com',
    emailFieldErrorMsg: 'Please enter your email',
    passwordFieldLabel: 'Password',
    passwordFieldPlaceholder: 'Password',
    passwordFieldErrorMsg: 'Please enter your password'
  },
  signup: {
    getStartedText: 'Get Started For Free!',
    nameFieldLabel: 'Name',
    nameFieldPlaceholder: 'Ex: Nadia Ali',
    phoneFieldLabel: 'Phone',
    phoneFieldPlaceholder: 'Ex: 01005511551',
    emailFieldLabel: 'Email',
    emailFieldPlaceholder: 'username@gmail.com',
    businessNameFieldLabel: 'Business Name',
    businessNamePlaceholder: 'Ex: Fincart',
    industryFieldLabel: 'Industry',
    monthlyOrdersLabel: 'Avg. Monthly Orders',
    alreadyHaveAnAccountText: 'Already have an account?',
    submitBtn: 'Submit'
  }
};

export default defaultLocale;
