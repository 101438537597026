import React from "react"

const LockIcon = () =>(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.4 8H5.6C4.99249 8 4.5 8.50368 4.5 9.125V15.875C4.5 16.4963 4.99249 17 5.6 17H14.4C15.0075 17 15.5 16.4963 15.5 15.875V9.125C15.5 8.50368 15.0075 8 14.4 8Z" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.5 8V6.5C13.5 5.57174 13.1313 4.6815 12.4749 4.02513C11.8185 3.36875 10.9283 3 10 3C9.07174 3 8.1815 3.36875 7.52513 4.02513C6.86875 4.6815 6.5 5.57174 6.5 6.5V8" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 12.5C10 13.0523 10 13.5 10 13.5C10 13.5 10 13.0523 10 12.5C10 11.9477 10 11.5 10 11.5C10 11.5 10 11.9477 10 12.5Z" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default LockIcon