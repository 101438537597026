import {
  CardIcon, CoinsStackIcon, CourierIcon, GlobeIcon, DecathlonLogo,
  GraphIcon, RecommendedIcon, PersonIcon, OrderIcon, ShahdLogo,
  HKLogo, MoreColorsLogo, MissGoldLogo, LibraLogo, TamwenatLargeLogo,
  CaseUpLogo, OchuuLogo, BooLogo, MakkahLogo, ChampsLandLogo, RavinLogo, LazurdeLogo
} from "./assets/icons";


export const BENEFITS = [
  { key: 'competitive-rates', title: 'Competitive Rates', desc: 'Access exclusive shipping rates with Fincart', icon: CoinsStackIcon },
  { key: 'higher-rate', title: 'Higher Success Rate', desc: 'Merchants have up to 8% improvement in their delivery', icon: GraphIcon },
  { key: 'financing', title: 'Financing', desc: 'Unlock financing options to grow your business', icon: RecommendedIcon },
  { key: 'cash-collection', title: 'Easy Cash Collection', desc: 'We consolidate the cash from all couriers in Fincart’s platform', icon: CardIcon },
  { key: 'wider-coverage', title: 'Wider Coverage', desc: 'Our variety of couriers ensures all areas are covered', icon: GlobeIcon },
  { key: 'click-switch', title: 'Switch with a Click', desc: 'If you don’t like a courier, choose another just like that!', icon: CourierIcon }
]

export const REINFORCEMENTS = [
  { key: 'performance-analytics', title: 'Performance Analytics', desc: 'Utilize data to your advantage to improve your shipping', icon: CoinsStackIcon },
  { key: 'merchant-advisor', title: 'Merchant Advisor', desc: 'Our expert advisors provide round-the clock support', icon: PersonIcon },
  { key: 'no-Minimum-orders', title: 'No Minimum Orders', desc: '10 or 1000 orders? there is no required monthly limit', icon: OrderIcon },
]

export const MERCHANTS = {
  decathlon: { key: 'decathlon', label: 'Decathlon', logo: DecathlonLogo },
  lazurde: { key: 'lazurde', label: 'Lazurde', logo: LazurdeLogo },
  hk: { key: 'hk', label: 'HL', logo: HKLogo },
  moreColors: { key: 'moreColors', label: 'More Colors', logo: MoreColorsLogo },
  libra: { key: 'libra', label: 'Libra', logo: LibraLogo },
  missGold: { key: 'miss-gold', label: 'MISS GOLD', logo: MissGoldLogo },
  ravin: { key: 'ravin', label: 'Ravin', logo: RavinLogo },
  shahd: { key: 'shahd', label: 'Shahd', logo: ShahdLogo },
  caseUp: { key: 'caseUp', label: 'Case Up', logo: CaseUpLogo },
  ochuu: { key: 'ochuu', label: 'Ochuu', logo: OchuuLogo },
  boo: { key: 'boo', label: 'Boo', logo: BooLogo },
  makkah: { key: 'makkah', label: 'Makkah', logo: MakkahLogo },
  tamwenat: { key: 'tamwenat', label: 'Tamwenat', logo: TamwenatLargeLogo },
  champsLand: { key: 'champsLand', label: 'champsLand', logo: ChampsLandLogo }
}

export const MERCHANTS_LOGOS = Object.values(MERCHANTS)

export const FINCART_URL = 'https://fincart.io/';
