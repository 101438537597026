import React from "react"

const HomeIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.58325 9.41675V16.483C4.58325 16.6202 4.63513 16.7517 4.72748 16.8486C4.81983 16.9456 4.94508 17.0001 5.07568 17.0001H14.9242C15.0548 17.0001 15.18 16.9456 15.2724 16.8486C15.3647 16.7517 15.4166 16.6202 15.4166 16.483V9.41675" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16 9.41667H4C3.86739 9.41667 3.74021 9.36479 3.64645 9.27244C3.55268 9.18009 3.5 9.05484 3.5 8.92424V6.95456L4.72 4.54169C4.80386 4.37778 4.93257 4.2402 5.09159 4.14449C5.25062 4.04879 5.43364 3.99875 5.62 4.00002H14.38C14.5664 3.99875 14.7494 4.04879 14.9084 4.14449C15.0674 4.2402 15.1961 4.37778 15.28 4.54169L16.5 6.95456V8.92424C16.5 9.05484 16.4473 9.18009 16.3536 9.27244C16.2598 9.36479 16.1326 9.41667 16 9.41667Z" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.5 7.25H16.5" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <rect x="8.5" y="12" width="3" height="5" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default HomeIcon