import React from "react"

import Slider from "./feedback"
import Couriers from "./couriers"
import CardsGrid from "../common/cards-grid"
import FinancingServices from "./financing-services"
import ScrollingElement from "../common/scrolling-element"
import { BENEFITS, MERCHANTS_LOGOS, REINFORCEMENTS } from "../../global.constants"
import RecommendationEngine from "./recommendation-engine"
import StartShipping from "./start-shipping"
import defaultLocale from "../../defaultLocal"

const Content = () => {
  return (
    <div className="mt-[138px] flex flex-col items-center gap-20 md:gap-[152px] md:mt-[164px] justify-center">
      <Couriers />
      <CardsGrid title={defaultLocale.benefitsTitle} items={BENEFITS}/>
      <RecommendationEngine />
      <CardsGrid title={defaultLocale.supportTitle} items={REINFORCEMENTS}/>
      <FinancingServices />
      <ScrollingElement elements={MERCHANTS_LOGOS}/>
      <Slider />
      <StartShipping />
    </div>
  )
}

export default Content