import React from "react"
import useViewport from "../../../hooks/use-viewport"
import courierSelectionPng from '../../../assets/images/courier-selection.png';
import aramexCourierPng from '../../../assets/images/aramex-courier.png'
import fedexCourierPng from '../../../assets/images/fedex-courier.png'
import jtCourierPng from '../../../assets/images/jt-courier.png'
import defaultLocale from "../../../defaultLocal";

const RecommendationEngine = () => {
  const { isSmallScreen } = useViewport()

  return(
    <div className="pt-10 pb-9 md:py-[100px] bg-white w-full flex items-center justify-center">
      <div className="max-w-[1019px] flex flex-col items-center justify-center gap-4 md:gap-8">
        <div className="max-w-[336px] font-Poppins-Bold text-3xl md:max-w-[953px] text-orange md:font-Poppins-ExtraBold md:text-7xl text-center">{defaultLocale.deliveryOptimizeTitle}</div>
        { isSmallScreen? (
          <div className="relative w-full h-[280px] overflow-hidden">
            <img width="278px" height="107px" src={aramexCourierPng} alt="aramex-courier" className="m-auto absolute right-0 left-0 top-0"/>
            <img width="278px" height="106px" src={fedexCourierPng} alt="fedex-courier" className="m-auto absolute left-12 right-0 top-[85px]" />
            <img width="278px" height="105px" src={jtCourierPng} alt="jt-courier" className="m-auto absolute left-0 right-0 top-[170px]" />
          </div>) :
          <img src={courierSelectionPng} alt="courier-selection" />
        }
      </div>
    </div>
  )
}

export default RecommendationEngine